import { jsx as s, Fragment as v, jsxs as O } from "react/jsx-runtime";
import { useState as r, useEffect as b, useRef as $, useMemo as z } from "react";
import { u as B } from "../../index-37cec024.js";
import { getUserColor as T } from "../../utils/colors.js";
import "unique-names-generator";
import U from "./CursorSVG.js";
function J({
  userId: l,
  percentX: x,
  pageY: X,
  transitionDuration: u = 100,
  getUserColor: C = T
}) {
  const f = B()[l], [c, W] = r(window.innerWidth), [d, k] = r(window.innerHeight), [g, N] = r(window.scrollX), [p, R] = r(window.scrollY);
  b(() => {
    const n = () => {
      N(window.scrollX), R(window.scrollY);
    }, i = () => {
      W(window.innerWidth), k(window.innerHeight);
    };
    return window.addEventListener("scroll", n), window.addEventListener("resize", i), () => {
      window.removeEventListener("scroll", n), window.removeEventListener("resize", i);
    };
  }, []);
  const o = x * document.body.scrollWidth - g, e = X - p, Y = o < 0 || o > c || e < 0 || e > d, a = 15, E = Math.max(a, Math.min(c - a, o)), L = Math.max(a, Math.min(d - a, e)), h = $(null), [t, M] = r({
    width: 0,
    height: 0
  });
  b(() => {
    if (h.current) {
      const { width: n, height: i } = h.current.getBoundingClientRect();
      M({ width: n, height: i });
    }
  }, [l, f, g, p]);
  const S = z(() => {
    const w = {
      left: 20,
      // Default position to right of cursor
      top: 20
      // Default position below cursor
    }, y = o + w.left + t.width + 30 > c, H = e + w.top + t.height + 30 > d;
    return {
      left: y ? -(t.width + 5) : w.left,
      top: H ? -(t.height + 5) : w.top
    };
  }, [o, e, c, d, t]), m = C(l);
  return /* @__PURE__ */ s(v, { children: /* @__PURE__ */ s(
    "div",
    {
      className: "user-cursor-container",
      style: {
        transform: `translate(${E}px, ${L}px)`,
        transition: `transform ${u}ms linear`
      },
      children: Y ? /* @__PURE__ */ s(
        "div",
        {
          className: "indicator-dot",
          style: {
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: m
          }
        }
      ) : /* @__PURE__ */ O(v, { children: [
        /* @__PURE__ */ s(U, { color: m }),
        /* @__PURE__ */ s(
          "div",
          {
            ref: h,
            className: "cursor-label",
            style: {
              backgroundColor: m,
              transition: `all ${u * 2}ms linear`,
              ...S
            },
            children: f ?? l
          }
        )
      ] })
    }
  ) });
}
export {
  J as default
};
